import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CatalogService } from './catalog.service';
import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MissionsService {


  constructor(private storageSvc: StorageService, private http: HttpClient,) { }

  getMissions() {
    const url = environment.url + '/seth/missions/?limit=20&offset=0';
    const httpOptions = this.storageSvc.getAuthHeaders();

    return this.http.get(url, httpOptions).toPromise().then((data) => {
      return data;
    });
  }

  getMissionDetails(id: number) {
    const url = environment.url + '/seth/missions/' + id;
    const httpOptions = this.storageSvc.getAuthHeaders();

    return this.http.get(url, httpOptions).toPromise().then((data) => {
      return data;
    });
  }


  submitSurvey(surveyData: { mission: number; survey: any; answers: { question_id: any; content: any; }[]; }) {
    const url = environment.url + '/seth/response/answers/';
    const httpOptions = this.storageSvc.getAuthHeaders();
    return this.http.post(url, surveyData, httpOptions).toPromise().then((data) => {
      return data;
    });
  }

  enrollMissionByUser(surveyData: { mission_id: number }) {
    const url = environment.url + '/seth/response/enroll/';
    const httpOptions = this.storageSvc.getAuthHeaders();
    return this.http.post(url, surveyData, httpOptions).toPromise().then((data) => {
      return data;
    });
  }


  updateStep(data: { mission_response_id: number; actual_step: number; }) {
    const url = environment.url + '/seth/response/update-step/';
    const httpOptions = this.storageSvc.getAuthHeaders();
    return this.http.post(url, data, httpOptions).toPromise().then((data) => {
      return data;
    });
  }

  completedMission(data: { mission_response_id: number; }) {
    const url = environment.url + '/seth/response/complete/';
    const httpOptions = this.storageSvc.getAuthHeaders();
    return this.http.post(url, data, httpOptions).toPromise().then((data) => {
      return data;
    });
  }


  getIaToken(missionId: number, questionId: number) {
    const url = environment.url + '/seth/response/ai-token/';
    const httpOptions = this.storageSvc.getAuthHeaders();
    const data = {
      mission: missionId,
      question: questionId
    }

    return this.http.post(url, data, httpOptions).toPromise().then((data) => {
      return data;
    });
  }

  iaRequest(iaToken: string, imageBase64: string) {
    const url = 'https://ai.staging.inkremental.co/external/process'
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${iaToken}`
      })
    }
    return this.http.post(url, { image: imageBase64 }, httpOptions).toPromise().then((data) => {
      return data;
    });
  }

  async getIaResponse(iaToken: string, iaPk: string) {

    const url = `https://ai.staging.inkremental.co/process/${iaPk}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${iaToken}`
      })
    }
    return this.http.get(url, httpOptions).toPromise().then((data) => {
      return data;
    });

  }


  async waitForResponse(iaToken: string, iaPk: string) {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        this.getIaResponse(iaToken, iaPk).then((data: any) => {
          if (data.state === 'success') {
            clearInterval(interval);
            resolve(data);
          } else if (data.state === 'error') {
            clearInterval(interval);
            reject(data);
          }
        }).catch((error) => {
          clearInterval(interval);
          reject(error);
        });
      }, 5000);
    });
  }




  async validateImage(missionId: any, questionId: any, imageSrc: any, jsValidation: any) {
    // First get the AI token
    const aiResponse: any = await this.getIaToken(missionId, questionId);
    const aiToken = aiResponse.tk;
    const iaResponse: any = await this.iaRequest(aiToken, imageSrc);
    console.debug('IA Response:', iaResponse);
    const iaPk = iaResponse.pk;
    const iaResult: any = await this.waitForResponse(aiToken, iaPk);
    console.debug('IA Result:', iaResult);
    // TODO Validate the result with the JS validation
    let iaValidation: any = this.validateIaAnswer(iaResult, jsValidation);
    return {
      isValid: iaValidation.is_valid,
      message: iaValidation.notes,
      request_id: iaPk,
      ia_answer: iaResult.result,
      showAlert: iaValidation.showAlert != undefined ? iaValidation.showAlert : false
    }
  }


  validateIaAnswer(iaResponse: any, jsValidation: any) {
    let iaData = iaResponse.result;
    let isValid = iaData.is_valid;
    let validationData: any;
    if (jsValidation) {
      try {
        let validationFunction = new Function('imageData', `${jsValidation};`);
        validationData = validationFunction(iaData);
        console.debug('Validation Data:', validationData);
      } catch (error) {
        console.error('Error validating IA answer:', error);
      }
    }
    let isValidJs = true;
    let notes = '';
    if (validationData) {
      isValidJs = validationData.is_valid;
      notes = validationData.notes;
    }
    if (!isValid && !isValidJs && !notes) {
      notes = iaData.summary_result;
    }
    return {
      is_valid: isValid && isValidJs,
      notes: notes,
      showAlert: validationData?.showAlert
    }
  }

}
