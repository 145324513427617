import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AlertController, IonModal, PopoverController } from '@ionic/angular';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Question } from 'src/app/interfaces/question';
import {
  FileUploadControl,
  FileUploadValidators,
} from '@iplab/ngx-file-upload';
import { CameraService } from 'src/app/services/camera.service';
import { ToolsService } from 'src/app/services/tools.service';
import { MissionsService } from 'src/app/services/missions.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {

  @ViewChild('iaQuestionModal') iaQuestionModal?: IonModal;


  form!: FormGroup;
  formIndex!: number;
  photo: { [key: number]: string } = {};

  isLoading: any;
  formDescription: SafeHtml | null = null;
  @Input() formValues: any = {};

  @Input() questions: Array<Question> = [];
  @Input() description: string = '';
  @Input() missionId: number | undefined;
  @Output() formDataChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  public fileUploadControl = new FileUploadControl(
    { multiple: false, discardInvalid: true, accept: ['jpg'] },
    FileUploadValidators.filesLimit(1)
  );

  public uploadedFiles: Array<File> = [];

  // Question IA Settings
  public selectedIaQuestion: Question | null = null;
  public questionInstruction: SafeHtml | null = null;


  file: any;
  constructor(
    private popoverController: PopoverController,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private cameraSvc: CameraService,
    private toolsService: ToolsService,
    private missionService: MissionsService, private alertController: AlertController
  ) { }

  ngOnInit() {
    this.formDescription = this.sanitizeHTML(this.description);
    console.log('preguntas', this.questions)
    this.form = this.createForm();
    this.formDataChange.emit(this.form);
    this.form.valueChanges.subscribe((value) => {
      this.formDataChange.emit(this.form);
    });

    if (this.formValues) {
      const patchedValues = this.getPatchedValues();
      this.form.patchValue(patchedValues);
    }
    console.log(this.form)
  }

  sanitizeHTML(description: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

  getPatchedValues() {
    const patchedValues = { ...this.formValues };

    this.questions.forEach((question) => {
      if (question.resourcetype === 'DateQuestion') {
        if (patchedValues[question.id]) {
          const dateValue = new Date(patchedValues[question.id]);
          patchedValues[question.id] = dateValue.toISOString().substring(0, 10);
        }
      }
    });

    return patchedValues;
  }

  createForm(): FormGroup {
    const group: any = {};
    console.log('creando el form:',this.questions)
    this.questions.forEach((question) => {
      if (
        question.resourcetype === 'TextQuestion' ||
        question.resourcetype === 'DateQuestion'
      ) {
        group[question.id] = new FormControl('', Validators.required);
      } else if (question.resourcetype === 'UniqueQuestion') {
        group[question.id] = new FormControl('', Validators.required);
      } else if (question.resourcetype === 'ImageQuestion') {
        group[question.id] = new FormControl('');
      } else if (question.resourcetype === 'IAQuestion') {
        group[question.id] = new FormControl(null);
      }
    });

    return this.fb.group(group);
  }

  changeDate(question: Question, event: any) {
    question?.formControl?.setValue(event.format('YYYY-MM-DD'));
    this.popoverController.dismiss();
  }

  getErrorText(question: Question): string {
    const formControl = this.form.get(question.id.toString());
    if (formControl?.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  isFormValid(): boolean {
    return this.form.valid;
  }

  // convertToBase64(id: any) {
  //   const file = this.uploadedFiles[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       const base64String = reader.result as string;
  //       if (this.form.contains(id.toString())) {
  //         this.form.patchValue({ [id.toString()]: base64String });
  //       } else {
  //         console.warn(`El campo ${id} no existe en el formulario.`);
  //       }
  //     };
  //     reader.onerror = (error) => {
  //       console.error('Error al convertir el archivo a Base64:', error);
  //     };
  //   } else {
  //     console.warn('No se ha seleccionado ningún archivo.');
  //     // entrando a este fallo
  //   }
  // }

  convertToBase64(id: any) {
    if (!this.uploadedFiles || this.uploadedFiles.length === 0) {
      console.warn('No se ha seleccionado ningún archivo.');
      return;
    }

    const file = this.uploadedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result as string;
        if (this.form.contains(id.toString())) {
          this.form.patchValue({ [id.toString()]: base64String });
        } else {
          console.warn(`El campo ${id} no existe en el formulario.`);
        }
      };
      reader.onerror = (error) => {
        console.error('Error al convertir el archivo a Base64:', error);
      };
    }
  }

  async takePicture(questionId: number, question_upload_type: any) {
    const imageSrc = await this.cameraSvc.takePicture(question_upload_type);
    if (!imageSrc) return;

    this.photo[questionId] = imageSrc;

    const control = this.form.get(questionId.toString());
    if (control) {
      control.setValue(imageSrc);
      control.markAsDirty();
      control.updateValueAndValidity();
    }
  }




  // IA Question Settings

  closeModal() {
    // Close modal
    if (this.iaQuestionModal) this.iaQuestionModal.dismiss(null, 'cancel');
  }

  showQuestionModal(question: Question) {
    this.selectedIaQuestion = question;
    if (this.selectedIaQuestion.ai_instruction) this.questionInstruction = this.sanitizeHTML(this.selectedIaQuestion.ai_instruction);
    else this.questionInstruction = null;

    console.debug('Selected question:', this.selectedIaQuestion);
    // Open modal
    if (this.iaQuestionModal) this.iaQuestionModal.present();
  }

  async selectIaPicture() {
    const imageSrc = await this.cameraSvc.takePicture(this.selectedIaQuestion?.type_upload);
    if (!imageSrc) return;
    await this.toolsService.presentIaLoading('Validando imagen...');
    let response;
    try {
      response = await this.missionService.validateImage(this.missionId, this.selectedIaQuestion?.id, imageSrc, this.selectedIaQuestion?.js_validation);
    } catch (error) {
      console.error('Error validating image:', error);
      await this.toolsService.dismissIaLoading();
      return;
    }
    await this.toolsService.dismissIaLoading();
    // If no is valid
    if (!response.isValid) return this.showIaAnswerAlert(response);
    // If is valid
    console.debug('IA Response:', response);
    this.closeModal();
    let questionId: any = this.selectedIaQuestion?.id;
    const control = this.form.get(questionId.toString());

    if (control) {
      console.debug('Control:', control);
      control.setValue({
        content: imageSrc,
        request_id: response.request_id,
        ia_answer: response.ia_answer,
      });
      control.markAsDirty();
      control.updateValueAndValidity();
      if (questionId) this.photo[questionId] = imageSrc;
    }
    console.debug('Form ia:', control);
    if(response.showAlert)this.showIaAnswerAlert(response);
  }

  async showIaAnswerAlert(iaResponse: any) {
    const alert = await this.alertController.create({
      header: 'Resultado',
      message: iaResponse.message,
      buttons: ['OK']
    });
    await alert.present();
  }



}
