import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { ProfileComponent } from './profile/profile.component';
import { RedemptionsComponent } from './redemptions/redemptions.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { ProfileDataComponent } from './profile-data/profile-data.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { AccountTabComponent } from './account-tab/account-tab.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdicionalInfoFormComponent } from './adicional-info-form/adicional-info-form.component';
import { MissionComponent } from './mission/mission.component';
import { FormComponent } from './form/form.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { PreRegisterFormComponent } from './pre-register-form/pre-register-form.component';
import { PlatformMobileModalComponent } from './platform-mobile-modal/platform-mobile-modal.component';
import { ProfileFieldsFormComponent } from './profile-fields-form/profile-fields-form.component';

@NgModule({
  declarations: [
    HeaderComponent,
    NavbarComponent,
    ProgressBarComponent,
    AdicionalInfoFormComponent,
    AccountTabComponent,
    ProductDetailComponent,
    FooterComponent,
    ProfileComponent,
    RedemptionsComponent,
    TransactionsComponent,
    ProfileDataComponent,
    MissionComponent,
    FormComponent,
    PreRegisterFormComponent,
    PlatformMobileModalComponent,
    ProfileFieldsFormComponent
  ],
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FormsModule, FileUploadModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [
    HeaderComponent,
    ProfileComponent,
    RedemptionsComponent,
    ProgressBarComponent,
    AdicionalInfoFormComponent,
    AccountTabComponent,
    TransactionsComponent,
    ProfileDataComponent,
    NavbarComponent,
    FooterComponent,
    ProductDetailComponent,
    MissionComponent,
    FormComponent,
    PreRegisterFormComponent,
    PlatformMobileModalComponent,
    ProfileFieldsFormComponent
  ],
})
export class ComponentsModule {}
