import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-profile-fields-form',
  templateUrl: './profile-fields-form.component.html',
  styleUrls: ['./profile-fields-form.component.scss'],
})
export class ProfileFieldsFormComponent  implements OnInit {
  @Input() profileFields: any = [];
  userForm: FormGroup;
  isSubmitting = false;
  isLoading: boolean = true;

  constructor(
    private fb: FormBuilder, private toolsService: ToolsService, private authService: AuthService, private router: Router
  ) {
    this.userForm = this.fb.group({});
  }

  ngOnInit() {
    this.loadProfileFields();
  }

  loadProfileFields() {
    this.isLoading = true;
    try {
      // console.log(this.profileFields)

      this.profileFields.forEach((field: { required: any; type: string; value: any; disabled: any; name: any; options: any}) => {
        let validators = [];
        if (field.required) {
          validators.push(Validators.required);
        }
        if (field.type === 'email') {
          validators.push(Validators.email);
        }

        const control = this.fb.control(
          { value: field.value || '',
          disabled: field.disabled || false },
          validators
        );

        this.userForm.addControl(field.name, control);
        this.isLoading = false;
      });
    } catch (error) {
      console.error('Error al cargar los campos del perfil:', error);
      this.isLoading = false;
    }
  }

  createFormData() {
    const formData: any = {};
    for (const field of this.profileFields) {
      formData[field.name] = this.userForm.get(field.name)?.value;
    }
    return formData;
  }

  isStringArray(options: any[]): boolean {
    return options.length > 0 && typeof options[0] === 'string';
  }

  // async onSubmit() {
  //   if (this.userForm.valid) {
  //     this.isSubmitting = true;
  //     await this.toolsService.loadingPresent('Actualizando...');

  //     try {
  //       const formData = this.createFormData();
  //       await this.authService.createOrUpdateProfile(formData);
  //       this.toolsService.presentSuccefulToast('Perfil actualizado correctamente');
  //       this.router.navigate(['/home']);
  //     } catch (error) {
  //       console.error('Error al actualizar el perfil:', error);
  //       this.toolsService.presentErrorToast('Error al actualizar el perfil');
  //     } finally {
  //       this.isSubmitting = false;
  //       this.toolsService.loadingDismiss();
  //     }
  //   } else {
  //     this.userForm.markAllAsTouched();
  //   }
  // }

  async onSubmit() {
    if (!this.userForm.valid) {
      this.userForm.markAllAsTouched();
      this.toolsService.presentErrorToast('Por favor, completa los campos obligatorios.');
      return;
    }

    this.isSubmitting = true;
    await this.toolsService.loadingPresent('Validando...');

    try {
      const hasRequiredData = this.checkRequiredFields();
      if (!hasRequiredData) {
        this.router.navigate(['/home']);
        this.toolsService.loadingDismiss();
        return;
      }
      const formData = this.createFormData();
      await this.authService.createOrUpdateProfile(formData);

      this.toolsService.presentSuccefulToast('Perfil actualizado correctamente');
      this.router.navigate(['/home']);
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
      this.toolsService.presentErrorToast('Error al actualizar el perfil');
    } finally {
      this.isSubmitting = false;
      this.toolsService.loadingDismiss();
    }
  }

  private checkRequiredFields(): boolean {
    const controls = this.userForm.controls;

    for (const controlName in controls) {
      const control = controls[controlName];
      const isRequired = control.hasValidator(Validators.required);
      if (isRequired && control.value) {
        return true;
      }
    }

    return false;
  }

}
