import { Injectable } from '@angular/core';
import { ToastController, LoadingController, ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  iaModalAlert: any;
  loading: any;
  validate: any;

  constructor(
    private toastController: ToastController,
    private loaderController: LoadingController,
    private modalCtrl: ModalController
  ) { }

  async loadingPresent(message: string) {
    if (this.loading) return;
    this.loading = await this.loaderController.create({
      message: message,
      duration: 2000
    });
    this.loading.present();
  }


  async presentIaLoading(message: string) {
    if (this.iaModalAlert) return;
    this.iaModalAlert = await this.loaderController.create({
      message: message
    });
    this.iaModalAlert.present();
  }

  async dismissIaLoading() {
    if (!this.iaModalAlert) return;
    await this.iaModalAlert.dismiss();
    this.iaModalAlert = null;
  }

  async validateCode(message: string) {
    if (this.validate) return;
    this.validate = await this.loaderController.create({
      message: message,
      duration: 2000
    });
    this.validate.present();
  }

  async loadingDismiss() {
    await this.loading.dismiss();
    this.loading = null;
  }

  async presentErrorToast(message: string) {
    const toast = await this.toastController.create({
      position: "bottom",
      message: message,
      color: "danger",
      cssClass: 'custom-toast',
      duration: 8000
    });
    toast.present();
  }

  async presentSuccefulToast(message: string) {
    const toast = await this.toastController.create({
      position: "bottom",
      message: message,
      color: "success",
      cssClass: 'custom-toast',
      duration: 5000
    });
    toast.present();
  }
}
