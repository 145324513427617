import { Component, ViewChild } from '@angular/core';
import { IonContent, Platform } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { ConfigService } from './services/config.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Device } from '@capacitor/device';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild(IonContent) content!: IonContent;
  isLoaded: boolean = false;
  isSticky!: boolean;
  showNavbar!: boolean;
  showPlatformModal = false;

  constructor(private platform: Platform, public configService: ConfigService, private router: Router) {
    this.initializeApp();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Verifica si la ruta actual es '/login'
        this.showNavbar = !this.router.url.includes('/login');
      }
    });
    this.configService.companyLoaded.subscribe((company) => {
     setTimeout(() => {
      // console.log("LOADED", company);
      this.isLoaded = company ? true : false;
      this.checkPlatformAndShowModal();
     }, 1000);
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.configService
        .loadCompany()
        .then((company) => {
          console.debug(company);
        })
        .catch((err) => {
          console.debug(err);
        });
    });
  }

  onContentScroll(event: any) {
    this.isSticky = event.detail.scrollTop > 0;
  }

  scrollToTop() {
    this.content.scrollToTop(400);
  }

  async checkPlatformAndShowModal() {
    try {
      let device = await Device.getInfo();
      let isWebPlatform = device.platform === 'web';
      let deviceType = this.configService.company?.device_type;

      if (!deviceType) {
        console.error('Error: No se encontró el valor de device_type en el servicio.');
        this.showPlatformModal = false;
        return;
      }

      console.log('plataforma en backend:', deviceType);
      console.log('plataforma detectada por capacitor:', device.platform);

      if (deviceType === 'mobile' && isWebPlatform) {
        this.showPlatformModal = true;
        console.log('mostrar modal', this.showPlatformModal);
      } else {
        this.showPlatformModal = false;
        console.log('mostrar modal', this.showPlatformModal);
      }
    } catch (error) {
      console.error('Error al obtener la información del dispositivo:', error);
      this.showPlatformModal = false;
    }
  }
}
