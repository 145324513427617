import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, finalize, switchMap, throwError } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { ToolsService } from '../services/tools.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  constructor(
    private authService: AuthService, 
    private storageService: StorageService,
    private router: Router,
    private toolService: ToolsService,) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    req = req.clone({
      withCredentials: true,
    });

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('/auth/login') &&
          error.status === 401 ||  error.status == 422
        ) {
          return this.handle401Error(req, next);
        }
        // else if( error.status == 422){
        //   this.router.navigate(['/login']);
        // }

        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (this.storageService.isLoggedIn()) {
        return this.authService.refreshToken().pipe(
          switchMap((newToken: any) => {
            this.isRefreshing = false;
            this.storageService.saveRefreshToken(newToken['refresh_token'])
            this.storageService.saveToken(newToken['access_token'])
            // Clone the request with the new token
            const newRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${newToken['access_token']}`,
              },
            });
            // Retry the request with the new token
            return next.handle(newRequest);
          }),
          catchError((error) => {
            this.isRefreshing = false;
            this.authService.logout()
            this.router.navigate(['/login']);

            this.toolService.presentErrorToast("Sesión cerrada por vencimiento del token de actualización. Por favor, inicie sesión nuevamente.")
            return throwError(() => error);
          })
        );
      }
    }

    return next.handle(request);
  }
}

export const selectedMallInterceptor: HttpInterceptorFn = (req, next) => {
 

  const urlParts = req.url.split('/');
  const url = `${urlParts[0]}//${urlParts[2]}`;

  let sessionToken = window.sessionStorage.getItem('token')
  if(!req.url.includes('auth/refresh') && !req.url.includes('login') && !req.url.includes('/process')){
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });
  }

  
  return next(req.clone()).pipe(
    finalize(() => {
      // Set the status to false if there are any errors or the request is completed
      
    })
  );
};
